html {
  scroll-behavior: smooth;
}
body {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  overflow-x: hidden !important;
}
#parent-scroll-bar {
  overflow-x: hidden;
}

.chatCalc {
  right: calc(100vh - 43.2rem);
}
.slick-prev:before,
.slick-next:before {
  font-size: 30px !important;
  opacity: 1 !important;
}

.slick-prev,
.slick-next {
  top: 140% !important;
  padding: 10px;
}
.slick-prev {
  left: 5%;
}
.slick-next {
  left: 5% !important;
}
@media screen and (min-width: 1024px) and (max-width: 1084px) {
  .headerContainer {
    width: 100% !important;
    gap: 0 !important;
    padding-inline: 19px !important;
  }
  .odometer-value {
    font-size: 40px !important;
  }
  .logoContainer {
    margin-left: -8px !important;
  }
  .heroContainer {
    width: 100% !important;
  }
  .leftContainer {
    padding-inline: 20px !important;
  }
}
@media screen and (min-width: 422px) and (max-width: 639px) {
  .priceContainer {
    bottom: auto !important;
  }
}

@media screen and (min-width: 335px) and (max-width: 361px) {
  .priceText {
    font-size: 18px !important;
  }
  .priceNumber {
    font-size: 32px !important;
  }
  .priceContainer {
    gap: 20px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 957px) {
  .contentContainer {
    display: none !important;
  }
  .techTitle {
    padding-inline: 0 !important;
  }
  .headerContainer {
    position: fixed !important;
    top: 0 !important;
  }
  .tech1 {
    display: none !important;
  }
  .tech2 {
    display: block !important;
  }
  .back1 {
    justify-content: end !important;
  }
  .back2 {
    justify-content: start !important;
  }
  .img2 {
    left: 10% !important;
  }
  .back3 {
    justify-content: end !important;
  }
  .layoutContainer {
    margin-top: 95px !important;
  }
  .menuIcon {
    display: block !important;
  }
  .accountButton {
    font-size: 14px !important;
  }
}
@media only screen and (max-width: 767px) {
  .slick-prev,
  .slick-next {
    top: 120% !important;
    padding: 10px;
  }
  .slick-next {
    left: 15% !important;
  }
  .slick-prev {
    left: 10px !important;
  }
}
.btn1 {
  overflow: hidden;
  border: 1px solid transparent;
}
.btn1::after {
  content: "Open an Account";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: black;
  left: -100%;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  transition: 0.3s;
  color: white;
}
.btn1:hover::after {
  left: 0%;
}

.btn2 {
  overflow: hidden;
  border: 1px solid transparent;
}
.btn2::after {
  content: "Open an Account";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: black;
  left: -100%;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  transition: 0.3s;
  color: white;
}
.btn2:hover::after {
  left: 0%;
}
.btn3 {
  overflow: hidden;
  border: 1px solid white;
}
.btn3::after {
  content: "Request a Demo";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(205, 138, 43, 1);

  left: -100%;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  transition: 0.3s;
  color: white;
  border: 1px solid #937d2b;
}
.btn3:hover::after {
  left: 0%;
}
.custom-arrow {
  position: absolute;
  top: 120%;
  /* transform: translateY(-50%); */
  z-index: 2;
}

.next-arrow {
  left: 80px; /* Adjust position as needed */
}

.prev-arrow {
  left: 2px; /* Adjust position as needed */
}
/* odometer-custom.css */
.odometer {
  font-size: 100px;
  color: white; /* Set text color to white */
  background: none; /* Remove background color */
}
.odometer-digit {
  color: white !important;
  background: transparent !important;
  box-shadow: none !important;
}
.odometer .odometer-auto-theme {
  background: transparent !important;
}
.odometer.odometer-auto-theme,
.odometer.odometer-theme-car {
  background: transparent !important;
  padding: 0 !important;
}

.price-Background {
  background-image: url("/public/img/price.png");
  background-repeat: no-repeat;
  background-size: cover;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


@keyframes slowRotate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(45deg);
  }
  50% {
    transform: rotate(90deg);
  }
  75% {
    transform: rotate(135deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

.ball {
  animation: slowRotate 7s linear infinite;
  animation-play-state: running; 
}

.running {
  animation-play-state: running;
}

.paused {
  animation-play-state: paused;
}
 .mask1 {
  position: relative;
  background-color: rgba(0, 0, 0, 0.921); 
  transform: translate(156px, 182px) scaleX(1.3) scaleY(1.3); 
  -webkit-mask-image: url(../public/img/tech_background.png);
  mask-image: url(../public/img/tech_background.png);
  mask-repeat: no-repeat;
  width: 100%;
}

.mask2 {
  -webkit-mask-image: url(../public/img/ball-t.png);
  mask-image: url(../public/img/ball-t.png);
  mask-repeat: no-repeat;
}


